import './econ.scss'

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import fullscreen from "highcharts/modules/full-screen";

exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

const Econ = () => {
    const options = {
        chart: {
            type: 'line',
            height: '300',
            backgroundColor: 'rgba(225,225,225,0)',
            color: '#fff'
        },

        title: {
            text: 'Line Chart with 4 Lines',
            style:{
                color: 'white',
                fontSize: '25px'
            }
        },
        subtitle: {
            text: 'График с линиями прогноза и т.д.',
            align: 'left'
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            itemStyle: {
                fontSize:'14px',
                color: '#A0A0A0'
            },
            itemHoverStyle: {
                color: '#FFF'
            },
            itemHiddenStyle: {
                color: '#444'
            }
        },
        xAxis: {
            labels: {
                style: {
                    color: 'white'
                }
            },
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

        },
        yAxis: {
            labels: {
                style: {
                    color: 'white'
                }
            },
            title: {
                text: 'Values',
                style:{
                    color: 'white',
                    fontSize: '14px'
                }
            }
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 800
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    menuItems: [
                        "viewFullscreen", "printChart", "separator",
                        "downloadPNG", "downloadJPEG", "downloadPDF",

                    ]
                }
            }
        },
        series: [{
            name: 'Line 1',
            data: [1, 3, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        }, {
            name: 'Line 2',
            data: [2, 4, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13]
        }, {
            name: 'Line 3',
            data: [3, 5, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14]
        }, {
            name: 'Line 4',
            data: [4, 6, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15]
        }]
    };


   /* const handleClick = ()=>{
        highcharts.fullscreen.open()
    }
*/
    return (
        <div className='econMain'>
            <div className='ecoBlocks'>
                2024
            </div>
            <div className='ecoBlocks2'>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
                {/*<button onClick={handleClick}>Open fullscreen</button>*/}
            </div>
        </div>
    );
};

export default Econ;