import React from 'react';
import BlockShadow from "../../elements/BlockShadow";

const Resources = () => {
    return (
        <div>
            q
        </div>
    );
};

export default Resources;